"use client"

import { SwitchField } from "@src/components"
import { makeStyles } from "tss-react/mui"
import classNames from "classnames"
import { Controller } from "react-hook-form"
import { styleCommon } from "./styleCommon"
import Box from "@mui/material/Box"

const useStyles = makeStyles()({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  ...styleCommon
})

const FormSwitchField = props => {
  const { classes } = useStyles()

  const {
    name,
    showLabel = true,
    label,
    type = "text",
    haseye = false,
    hiddenField,
    errors,
    control,
    end_adornment,
    start_adornment,
    sxWrapper,
    ...rest
  } = props

  return (
    <Box
      className={classNames(classes.root)}
      sx={sxWrapper}
      hidden={hiddenField}
    >
      {showLabel && (
        <label
          className={classNames(classes.label, {
            [classes.errorLabel]: errors?.message
          })}
        >
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <SwitchField
            className={classes.controller}
            {...field}
            checked={field.value}
            {...rest}
          />
        )}
      />
    </Box>
  )
}
export default FormSwitchField
