"use client"

import React, { useMemo, useState, useCallback } from "react"
import ProductCard from "./ProductCard"
import useMutation from "@src/hooks/use-mutation"
import { Toastify } from "@src/components"
import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"
import { useCurrentUser } from "@src/hooks/use-current-user"
import ModalProduct from "./ModalProduct"

export const ProductCardSkeleton = () => (
  <Box width="100%">
    <Skeleton
      variant="rectangular"
      width="100%"
      height={330}
      sx={{
        borderRadius: 2
      }}
    />
    <Box sx={{ pt: 0.5 }}>
      <Skeleton width="100%" />
      <Skeleton width="60%" />
    </Box>
    <Box sx={{ pt: 0.5 }}>
      <Skeleton width="100%" />
      <Skeleton width="60%" />
    </Box>
  </Box>
)

const WrapperProductCard = props => {
  const { product, onCallBackToggleLike } = props
  const { update, user } = useCurrentUser()
  const { mutation } = useMutation()

  const [selectedProduct, setSelectedProduct] = useState(null)

  const { product_ids } = user || {}

  const isLiked = useMemo(() => {
    return product_ids?.includes(product?.id)
  }, [product_ids?.length, product?.id])

  const showModalDetail = useCallback(() => {
    setSelectedProduct(product)
  }, [])

  const onCloseModal = useCallback(() => {
    setSelectedProduct(null)
  }, [])

  const toggleLike = status => async () => {
    if (product?.slug) {
      if (status) {
        await mutation({
          url: `/auth/wishlist/product/${product.slug}/addToWishlist`,
          method: "POST",
          onSuccess: res => {
            if (res) {
              Toastify.success(res.message || "Đã thêm vào yêu thích")
              update({
                ...user,
                product_ids: [...product_ids, product.id],
                type: "LOCAL_UPDATE"
              })
              onCallBackToggleLike?.()
            }
          }
        })
      } else {
        await mutation({
          url: `/auth/wishlist/product/${product.slug}/removeFromWishlist`,
          method: "DELETE",
          onSuccess: res => {
            if (res) {
              Toastify.success(res.message || "Đã xóa khỏi yêu thích")
              update({
                ...user,
                product_ids: product_ids.filter(
                  item => item !== product.id
                ),
                type: "LOCAL_UPDATE"
              })
              onCallBackToggleLike?.()
            }
          }
        })
      }
    }
  }

  return (
    <>
      <ProductCard
        {...props}
        isLiked={isLiked}
        toggleLike={toggleLike}
        onSelectedProduct={showModalDetail}
      />
      {selectedProduct && (
        <ModalProduct
          open={true}
          selectedProduct={product}
          onClose={onCloseModal}
        />
      )}
    </>
  )
}

export default WrapperProductCard
