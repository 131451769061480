"use client"

import React, { forwardRef } from "react"
import Container from "@mui/material/Container"
import Box from "@mui/material/Box"

interface Props {
  children: React.ReactNode
  sxWrapper?: object
  sx?: object
}

const SectionContainer = ({ children, sxWrapper, sx, ...rest }: Props) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        margin: {
          xs: "16px 12px",
          md: "32px 0px"
        },
        borderRadius: {
          xs: "16px",
          md: 0
        },
        ...sxWrapper
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          maxWidth: "xl",
          position: "relative",
          display: "flex",
          padding: {
            xs: "16px 12px",
            md: "32px 16px"
          },
          ...sx
        }}
        {...rest}
      >
        {children}
      </Container>
    </Box>
  )
}

export default SectionContainer
