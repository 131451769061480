"use client"
import Container from "@mui/material/Container"
import { forwardRef } from "react"
import type { SxProps } from "@mui/system"

interface Props {
  [key: string]: any
  children: React.ReactNode
  sxWrapper?: SxProps
  sx?: SxProps
}

const AppContainer = forwardRef<
  HTMLDivElement,
  Props
>(({ children, sxWrapper, sx, ...rest }, ref) => {
  return (
    <Container
      maxWidth="xl"
      ref={ref}
      sx={{
        maxWidth: true,
        backgroundColor: "white",
        padding: {
          xs: "32px 12px 0px 12px",
          md: "48px 16px 0px 16px"
        },
        ...sx
      }}
      {...rest}
    >
      {children}
    </Container>
  )
})

export default AppContainer
