import React, { useMemo } from "react"
import Box from "@mui/material/Box"

const JobStatusColor = {
  hot: {
    backgroundColor: "#FF675E;",
    color: "white"
  },
  new: {
    backgroundColor: "#73D13D",
    color: "white"
  },
  close: {
    backgroundColor: "#C9C9C9;",
    color: "white"
  }
}

// featured // status host
// is_expired  // close | new
// wishlists_count  // like

const JobStatus = ({ featured, is_expired }) => {
  const status = {
    hot: featured,
    new: !featured && !is_expired,
    close: !featured && is_expired
  }

  const text = Object.keys(status).filter(key => status[key])
  const style = JobStatusColor[text[0]]

  return (
    <Box
      sx={{
        borderRadius: "8px",
        height: 32,
        minWidth: 32,
        fontSize: 14,
        fontWeight: 600,
        padding: "6px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textTransform: "capitalize",
        ...style
      }}
    >
      {text}
    </Box>
  )
}

export default JobStatus
