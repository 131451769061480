"use client"

import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import { makeStyles } from "tss-react/mui"
import classNames from "classnames"
import { useRef } from "react"
import { Controller } from "react-hook-form"
import { styleCommon } from "./styleCommon"
import { ConfirmButton, Icons } from "@src/components"
import Stack from "@mui/material/Stack"
import { height, minHeight } from "@mui/system"

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& .MuiInputBase-root": {
      padding: 0,
      cursor: "pointer"
    },
    "& .MuiInputBase-input": {
      height: "100%",
      width: "100%",
      padding: "0px 16px !important",
      marginBottom: 0
    },
    "& .MuiInputBase-adornedStart": {
      paddingLeft: "8px"
    },
    "& .MuiInputLabel-root": {
      transform: "none",
      position: "unset",
      fontSize: 12,
      lineHeight: "16px",
      color: "#000000",
      fontWeight: "400",
      marginBottom: 4,
      display: "inline-block"
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336"
    },
    "& .MuiInputBase-root > .MuiIcon-root": {
      cursor: "pointer",
      fontSize: 15
    }
  },
  ...styleCommon
}))

const FormTextField = props => {
  const { classes } = useStyles()
  const inputRef = useRef(null)
  const inputMuiRef = useRef(null)

  const {
    required,
    name,
    showLabel = true,
    label,
    placeholder,
    type = "text",
    hiddenField,
    errors,
    control,
    end_adornment,
    start_adornment,
    className = "field",
    inputProps,
    ...rest
  } = props

  const msgError = errors?.[name]?.message

  const onFileChangeCapture = e => {
    /* Selected files data can be collected here. */
    const file = e.target.files[0]
    if (inputMuiRef.current) {
      inputMuiRef.current.value = file?.name || ""
    }
  }

  const handleUpload = () => {
    inputRef.current.click()
  }

  return (
    <Box
      className={classNames(classes.root, className)}
      sx={{
        ...(hiddenField && {
          height: 0,
          overflow: "hidden",
          zIndex: -1
        })
      }}
    >
      {showLabel && (
        <label
          className={classNames(classes.label, {
            [classes.errorLabel]: Boolean(msgError)
          })}
        >
          {label}
          {required && <span className="required">*</span>}
        </label>
      )}

      <Stack spacing={3}>
        <TextField
          inputRef={inputMuiRef}
          className={classNames(classes.controller, {
            [classes.errorField]: Boolean(msgError)
          })}
          type="text"
          disabled={true}
          variant="outlined"
          size="small"
          placeholder={placeholder || label}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            notched: false,
            startAdornment: start_adornment,
            endAdornment: end_adornment,
            ...inputProps
          }}
          fullWidth
          error={Boolean(msgError)}
          {...rest}
          onClick={handleUpload}
        />
        <ConfirmButton
          sx={{
            minWidth: {
              xs: "110px !important",
              md: "200px !important"
            },
            minHeight: "52px",
            height: "52px",
          }}
          label="Tải lên CV"
          endIcon={<Icons name="document" />}
          onClick={handleUpload}
        />
      </Stack>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, name, ref } }) => (
          <input
            required={required}
            name={name}
            type="file"
            ref={inputRef}
            style={{ display: "none" }}
            onBlur={onBlur}
            onChange={e => {
              onChange(e.target.files[0])
            }}
            onChangeCapture={onFileChangeCapture}
          />
        )}
      />
      {msgError && <span className={classes.errorText}>{msgError}</span>}
    </Box>
  )
}
export default FormTextField
