import { useState, useEffect, useRef, useCallback } from 'react'
import ProductModel from "@src/models/product"
import { NextImage, Icons } from "@src/components"
import Box from "@mui/material/Box"
import Image from "next/image"
import Stack from "@mui/material/Stack"
import { styled } from "@mui/system"
import useDomLoaded from "@src/hooks/use-dom-loaded";
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper/modules"
import Skeleton from '@mui/material/Skeleton'


const WrapperSwiper = styled(Box)({
  position: "relative",
  "& .mySwiper": {
    maxHeight: "564px",
    height: "100%"
  }
})

interface Props {
  isModal?: boolean
  detail: ProductModel
  selectedImage: string
}

const ImageCarouselDesktop = (props: Props) => {
  const { detail, selectedImage } = props
  const carouselRef = useRef(null)
  const isDomLoaded = useDomLoaded()

  const [isEnd, setIsEnd] = useState(false)
  const [isBeginning, setIsBeginning] = useState(true)

  const [currentIndexImage, setCurrentIndexImage] = useState(0)
  const [currentImage, setCurrentImage] = useState<string>("")


  useEffect(() => {
    if (carouselRef.current) {
      carouselRef.current.swiper.on("slideChange", () => {
        setIsEnd(carouselRef.current?.swiper?.isEnd)
        setIsBeginning(carouselRef.current?.swiper?.isBeginning)
      })
    }
  }, [carouselRef])

  useEffect(() => {
    if (detail?.images?.[currentIndexImage]) {
      setCurrentImage(detail?.images?.[currentIndexImage])
    }
  }, [detail])

  useEffect(() => {
    if (selectedImage) {
      setCurrentImage(selectedImage)
      const index = detail?.images?.findIndex(image => image === selectedImage)
      if (index !== -1) {
        setCurrentIndexImage(index)
      } else {
        setCurrentIndexImage(-1)
      }
    }
  }, [selectedImage])

  const handlePrevSlide = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.swiper.slidePrev()
    }
  }, [])

  const handleNextSlide = useCallback(() => {
    if (carouselRef.current) {
      carouselRef.current.swiper.slideNext()
    }
  }, [])

  const handleChangeImage = useCallback(
    index => () => {
      setCurrentIndexImage(index)
      if (detail?.images?.[index]) {
        setCurrentImage(detail?.images?.[index])
      }
    },
    [detail]
  )

  if (!isDomLoaded) {
    return (
      <Skeleton
        sx={{
          marginTop: 1,
          borderRadius: "8px",
          width: "60%",
          height: "auto",
          minHeight: "600px"
        }}
        variant="rectangular"
        animation="wave"
      />
    )
  }

  return (
    <Stack gap="32px" width="100%" height="100%">
      <NextImage
        src={
          currentImage ||
          "/assets/images/no-image.png"
        }
        sx={{
          height: "100%",
          width: "100%",
          maxWidth: "calc(100% - 120px)",
          minHeight: "480px",
          aspectRatio: "4/5",
          "& img": {
            position: "relative !important",
            objectFit: "cover",
            borderRadius: "8px",
          }
        }}
        loading="eager"
        priority={true}
        alt={detail?.name || ""}
      />
      <WrapperSwiper>
        {!isBeginning && (
          <Stack
            justifyContent="center"
            sx={{
              position: "absolute",
              zIndex: 99,
              display: "flex",
              justifyContent: "center",
              top: 5,
              left: "50%",
              transform: "translateX(-50%)"
            }}
          >
            <Icons
              sx={{
                background: "white",
                "&:hover": {
                  background: "white",
                  opacity: 0.9
                }
              }}
              name="arrow-up"
              type="button"
              onClick={handlePrevSlide}
            />
          </Stack>
        )}

        <Swiper
          ref={carouselRef}
          direction={"vertical"}
          slidesPerView={4}
          spaceBetween={28}
          navigation={false}
          pagination={false}
          className="mySwiper"
          modules={[Pagination, Navigation]}
          width={88}
        >
          {detail?.images?.map((image, index) => (
            <SwiperSlide key={index} onClick={handleChangeImage(index)}>
              <Box
                width={88}
                height={120}
                borderRadius="8px"
                overflow="hidden"
                sx={{
                  cursor: "pointer",
                  boxSizing: "border-box",
                  border: "1px solid transparent",
                  "&:hover": {
                    border: "2px solid #000"
                  },
                  ...(currentIndexImage === index && {
                    border: "2px solid #000"
                  })
                }}
              >
                <Image
                  width={88}
                  height={120}
                  src={image}
                  style={{ objectFit: "cover" }}
                  alt={detail?.name || ""}
                />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
        {detail?.images?.length > 4 && (
          <Stack justifyContent="center">
            <Icons
              name="arrow-down"
              type="button"
              disabled={isEnd}
              sx={{
                "&::disabled": {
                  opacity: 0.5
                }
              }}
              onClick={handleNextSlide}
            />
          </Stack>
        )}
      </WrapperSwiper>
    </Stack>

  )

}

export default ImageCarouselDesktop