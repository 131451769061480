import { InventoryAttribute } from "@src/types/common";

export default class InventoryModel {
  id: number;
  image: string;
  stock_quantity: number;
  price: number;
  product_id: number;
  quantity_each_order: number;
  promotion_price: number | null;
  attributes: InventoryAttribute[];
  promotion_from: string | null;
  promotion_to: string | null;
  promotion_status: number;

  static fromJson(json) {
    const inventory = new InventoryModel();
    for (const key in json) {
      if (json.hasOwnProperty(key)) {
        inventory[key] = json[key];
      }
    }
    return inventory;
  }

  get hasPromotion() {
    return this.promotion_price > 0
  }

  get salePrice() {
    return this.promotion_price ? this.promotion_price : this.price;
  }

  get firstAttribute() {
    return this.attributes?.length ? this.attributes[0] : null
  }

  get secondAttribute() {
    return this.attributes?.length > 1 ? this.attributes[1] : null 
  }
}