import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import { Icons } from "@src/components"

const DialogTitle = props => {
  const { children, classes, onClose } = props
  return (
    <Stack
      sx={{
        py: 1.5,
        px: 2
      }}
      flex
      justifyContent="space-between"
      alignItems="center"
      flexDirection="row"
    >
      <Typography variant="h4">{children}</Typography>
      <Icons
        sx={{
          backgroundColor: "transparent !important"
        }}
        name="close"
        type="button"
        onClick={onClose}
      />
    </Stack>
  )
}
export default DialogTitle
