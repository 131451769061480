"use client"

import React from "react"
import Switch from "@mui/material/Switch"
import { styled } from "@mui/material/styles"

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 38,
  height: 34,
  padding: 7,
  paddingLeft: 0,
  paddingRight: 0,
  "& .MuiSwitch-switchBase": {
    marginTop: 9,
    marginLeft: -3,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage:
          'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none"><path fill="%231866E1" d="M7.60608 0.707083C7.99651 1.09751 7.99655 1.73052 7.60615 2.12099L3.95011 5.77775C3.55959 6.16834 2.92637 6.16837 2.53582 5.77782L0.707 3.949C0.316535 3.55853 0.316535 2.92547 0.707 2.535C1.09747 2.14453 1.73053 2.14454 2.121 2.535L3.243 3.657L6.19201 0.70718C6.58246 0.316631 7.21559 0.316588 7.60608 0.707083Z"/></svg>\')',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundColor: "white"
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#1866E1"
      }
    }
  },
  "& .MuiSwitch-thumb": {
    width: 16,
    height: 16,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundColor: "white",
      borderRadius: "50%",
      border: "none"
    }
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#aab4be",
    borderRadius: 20 / 2
  }
}))

const SwitchField = ({ checked, onChange }) => {
  return (
    <MaterialUISwitch
      checked={checked}
      onChange={e => onChange(e.target.checked)}
    />
  )
}

export default SwitchField
