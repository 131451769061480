"use client";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Skeleton from '@mui/material/Skeleton';
import { NextImage, Icons } from "@src/components";
import useDomLoaded from "@src/hooks/use-dom-loaded";
import { Swiper, SwiperSlide } from "swiper/react";
import { makeStyles } from "tss-react/mui";
import { Navigation } from "swiper/modules"
import { useRef, useEffect, useState, use } from "react";
import ProductModel from "@src/models/product"


const useStyles = makeStyles()(() => ({
  paginationBulletActive: {
    boxSizing: "border-box",
    background: "black",
    width: "12px",
    height: "12px"
  },
  mySwiper: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& .swiper-wrapper ": {
      height: "100%"
    },
  }
}))

interface ImageCarouselProps {
  name: string
  isLoading: boolean
  isModal?: boolean
  detail: ProductModel
  selectedImage?: string
}
const ImageCarousel = (props: ImageCarouselProps) => {
  const { selectedImage, isModal, isLoading, name, detail } = props

  const isDomLoaded = useDomLoaded()
  const { classes } = useStyles()

  const swiperRef = useRef(null)
  const [isEnd, setIsEnd] = useState(false)
  const [isBeginning, setIsBeginning] = useState(true)

  const [imageList, setImageList] = useState<string[]>([])

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.on("slideChange", () => {
        setIsEnd(swiperRef.current?.swiper?.isEnd)
        setIsBeginning(swiperRef.current?.swiper?.isBeginning)
      })
    }
  }, [swiperRef?.current, isDomLoaded])

  useEffect(() => {
    if (detail?.id) {
      const images = detail?.images || []
      const firstAttributesImage = detail?.attributes?.map(item => item?.image)
      setImageList([...images, ...firstAttributesImage])
    }
  }, [detail])

  useEffect(() => {
    if (selectedImage) {
      const index = imageList?.indexOf(selectedImage)
      if (swiperRef.current && index) {
        swiperRef.current.swiper.slideTo(index)
      }
    }
  }, [selectedImage])

  const handleNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const handlePrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  if (!isDomLoaded || isLoading) {
    return (
      <Skeleton
        sx={{
          marginTop: 1,
          borderRadius: "8px",
          height: "auto",
          minHeight: isModal ? "120px" : "480px"
        }}
        variant="rectangular"
        animation="wave"
      />
    )
  }

  return (
    <Box
      sx={{
        marginTop: 1,
        position: "relative",
        width: "auto",
        height: isModal ? "120px" : "480px",
      }}
    >
      {!isModal && (
        <Stack
          justifyContent="center"
          sx={{
            zIndex: 99,
            position: "absolute",
            display: "flex",
            top: '50%',
            left: 5,
            justifyContent: "center",
            transform: 'translateY(-50%)',
          }}
        >
          <Icons
            sx={{
              background: "rgba(255, 255, 255, 0.8)",
              "&:disabled": {
                background: "rgba(255, 255, 255, 0.3)"
              }
            }}
            name="caret-left"
            type="button"
            disabled={isBeginning}
            onClick={handlePrevSlide}
          />
        </Stack>
      )}
 
      {imageList?.length > 0 && (
        <Swiper
          ref={swiperRef}
          spaceBetween={10}
          slidesPerView={isModal ? "auto" : 1}
          pagination={{
            clickable: true,
            bulletActiveClass: classes.paginationBulletActive
          }}
          className={classes.mySwiper}
          modules={[Navigation]}
        >
          {(imageList || []).map(item => (
            <SwiperSlide key={item} style={{
              width: "auto",
            }}>
              <NextImage
                src={item}
                alt={name}
                loading="lazy"
                sx={{
                  width: isModal ? "120px !important" : "100% !important",
                  "& img": {
                    objectFit: "cover",
                    height: "100% !important",
                  }
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {!isModal && (
        <Stack justifyContent="center"
          sx={{
            zIndex: 99,
            position: "absolute",
            display: "flex",
            top: '50%',
            right: 5,
            justifyContent: "center",
            transform: 'translateY(-50%)',
          }}
        >
          <Icons
            name="caret-right"
            type="button"
            sx={{
              background: "rgba(255, 255, 255, 0.8)",
              "&:disabled": {
                background: "rgba(255, 255, 255, 0.3)"
              }
            }}
            disabled={isEnd}
            onClick={handleNextSlide}
          />
        </Stack>
      )}
 
    </Box>
  )
}

export default ImageCarousel