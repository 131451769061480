"use client"

import { useState, useEffect } from "react"
import GoogleMapReact from "google-map-react"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/system"

const Wrapper = styled("div")({
  height: "300px",
  width: "100%",
  borderRadius: "8px"
})

const Marker = styled("div")({
  color: "#104D8A",
  fontSize: 50,
  position: "relative",
  left: -25,
  top: -50
})

const DEFAULT_CENTER = {
  lat: parseFloat(process.env.NEXT_PUBLIC_DEFAULT_LATITUDE),
  lng: parseFloat(process.env.NEXT_PUBLIC_DEFAULT_LONGITUDE)
}
const DEFAULT_ZOOM = 14

const MapContainer = ({ label, isMapReady, defaultLatLng, children }) => {
  const [latLng, setLatLng] = useState()

  useEffect(() => {
    setLatLng(DEFAULT_CENTER)
  }, [])

  useEffect(() => {
    if (defaultLatLng) {
      setLatLng(defaultLatLng)
    }
  }, [defaultLatLng])

  if (!isMapReady) return null

  return (
    <>
      {label && (
        <Typography variant="body2" color="#7A8699">
          {label}
        </Typography>
      )}
      <Wrapper>
        {latLng && latLng.lat && latLng.lng && (
          <GoogleMapReact
            yesIWantToUseGoogleMapApiInternals
            resetBoundsOnResize
            bootstrapURLKeys={{
              key: process.env.NEXT_PUBLIC_GOOGLE_MAP_ID,
              libraries: "places"
            }}
            options={{
              zoomControl: false,
              fullscreenControl: false,
              gestureHandling: "greedy" // use one finger to move the map on mobile
            }}
            defaultCenter={DEFAULT_CENTER}
            center={latLng}
            defaultZoom={DEFAULT_ZOOM}
            // onClick={handleMapClick}
          >
            {children}
          </GoogleMapReact>
        )}
      </Wrapper>
    </>
  )
}

export default MapContainer
