import React from "react"
import Typography from "@mui/material/Typography"

const PriceTypography = ({ children, isNull, ...rest }) => {
  if (isNull) return null
  return (
    <Typography
      {...rest}
      sx={{
        opacity: 0.5,
        textDecoration: "underline",
        textUnderlineOffset: "-42%",
        textDecorationColor: "black",
        textDecorationSkipInk: "none"
      }}
    >
      {children}
    </Typography>
  )
}

export default PriceTypography
