"use client"

import PropTypes from "prop-types"
import { Controller } from "react-hook-form"
import Select from "react-select"
import classNames from "classnames"
import { makeStyles } from "tss-react/mui"
import { FIELD_HEIGHT, styleCommon } from "./styleCommon"

const useStyles = makeStyles()({
  root: {},

  controller: {
    "& > div:nth-of-type(1)": {
      boxShadow: "unset"
    }
  },
  ...styleCommon
})

const FormSelect = props => {
  const { classes } = useStyles()

  const { name, label, control, errors, options, ...rest } = props

  const customStyles = {
    control: styles => ({
      ...styles,
      height: FIELD_HEIGHT,
      borderColor: errors?.message ? "red" : styles.borderColor
    }),
    indicatorSeparator: styles => ({ ...styles, display: "none" }),
    menu: styles => ({ ...styles, zIndex: 1000 })
  }

  return (
    <div className={classes.root}>
      <label
        className={classNames(classes.label, {
          [classes.errorLabel]: errors?.message
        })}
      >
        {label}
      </label>
      <Controller
        className={classes.controller}
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState
        }) => (
          <Select
            onBlur={onBlur} // notify when input is touched
            onChange={onChange} // send value to hook form
            value={value} // select value to show
            name={name}
            isSearchable={false}
            options={options}
            styles={customStyles}
            {...rest}
          />
        )}
      />
      {errors?.message && (
        <span className={classes.errorText}>{errors.message}</span>
      )}
    </div>
  )
}

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  control: PropTypes.object.isRequired,
  options: PropTypes.array,
  errors: PropTypes.object
}

FormSelect.defaultProps = {
  label: "",
  options: [],
  errors: null
}

export default FormSelect
