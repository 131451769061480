"use client"
import Pagination from "@mui/material/Pagination"
import PaginationItem from "@mui/material/PaginationItem"
import Stack from "@mui/material/Stack"
import { withStyles } from "tss-react/mui"
import { styled } from "@mui/system"
import { Icons } from "@src/components"

const ArrowBackIcon = props => (
  <Icons
    {...props}
    name="caret-left"
    sx={{
      "&:hover": {
        backgroundColor: "transparent"
      }
    }}
  />
)
const ArrowForwardIcon = props => (
  <Icons
    {...props}
    name="caret-right"
    sx={{
      "&:hover": {
        backgroundColor: "transparent"
      }
    }}
  />
)

const StyledPagination = styled(Pagination)({
  "& .MuiPagination-ul li:last-child": {
    marginLeft: {
      xs: "12px",
      md: "28px"
    }
  },
  "& .MuiPagination-ul li:first-child": {
    marginRight: {
      xs: "12px",
      md: "28px"
    }
  }
})

const MuiPaginationItem = withStyles(PaginationItem, () => ({
  root: {
    color: "#141414",
    border: "none",
    fontSize: "14px",
    borderRadius: "8px",
    height: {
      xs: "32px",
      md: "36px"
    },
    width: {
      xs: "32px",
      md: "36px"
    },
    background: "#fff",
  },
  selected: {
    color: "white",
    backgroundColor: "#434343 !important"
  }
}))

interface Props {
  page: number
  total: number
  onChange: (page: number) => void
  sx?: any
}

export default function PaginationCustom({ page, total, onChange, sx }: Props) {
  if (!total || total <= 1) return null
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        paddingY: 3,
        background: "transparent",
        borderRadius: "16px",
        ...sx
      }}
      gap={1}
    >
      <StyledPagination
        page={Number(page)}
        count={total}
        variant="outlined"
        shape="rounded"
        onChange={(_, page) => onChange(page)}
        renderItem={item => (
          <MuiPaginationItem
            slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
            {...item}
            page={<span className="text">{item.page}</span>}
          />
        )}
      />
    </Stack>
  )
}
