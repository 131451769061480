import React, { useMemo } from "react"
import Breadcrumbs from "@mui/material/Breadcrumbs"
import Typography from "@mui/material/Typography"
import Icons from "../Icons"
import { NextLink } from "@src/components"

function BreadcrumbComponent({ items, ...props }) {
  const breadcrumbs = useMemo(
    () =>
      items.map((item, index) => {
        if (item.link) {
          return (
            <NextLink
              underline="hover"
              key={index}
              href={item.link}
              sx={{
                color: "#000"
              }}
            >
              {item.name}
            </NextLink>
          )
        } else {
          return (
            <Typography key={index} color="text.primary">
              {item.name}
            </Typography>
          )
        }
      }),
    [items]
  )
  return (
    <Breadcrumbs
      separator={<Icons name="arrow-next" />}
      aria-label="breadcrumb"
      {...props}
    >
      {breadcrumbs}
    </Breadcrumbs>
  )
}

export default BreadcrumbComponent
