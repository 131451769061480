"use client"

import { Icons } from "@src/components"
import InputAdornment from "@mui/material/InputAdornment"
import { makeStyles } from "tss-react/mui"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import classNames from "classnames"
import { Controller } from "react-hook-form"
import { styleCommon } from "./styleCommon"

const useStyles = makeStyles()({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",

    "& .MuiInputBase-root .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #C2C7D0"
    }
  },
  ...styleCommon
})

const CalendarIcon = () => {
  return <Icons name="calendar-blue" type="button" />
}

const FormMuiDatePicker = props => {
  const { classes } = useStyles()

  const {
    name,
    label,
    control,
    errors,
    options,
    disabled,
    showLabel = true,
    placeholder,
    ...rest
  } = props

  return (
    <div className={classes.root}>
      {showLabel && (
        <label
          className={classNames(classes.label, {
            [classes.errorLabel]: errors?.message
          })}
        >
          {label}
        </label>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <DatePicker
              className={classes.controller}
              {...field}
              inputVariant="outlined"
              autoOk={true}
              disableToolbar
              // format={formatDateTime}
              components={{
                OpenPickerIcon: CalendarIcon
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icons name="close" />
                  </InputAdornment>
                )
              }}
              label={placeholder}
              placeholder={placeholder}
              {...rest}
            />
          )}
        />
      </LocalizationProvider>
      {errors?.message && (
        <span className={classes.errorText}>{errors.message}</span>
      )}
    </div>
  )
}

export default FormMuiDatePicker
