"use client"
import React from "react"
import { Box } from "@mui/system"
import { formatHtmlBreak } from "@src/utils/common"

const DangerouslySetInnerHTML = props => {
  const { html, className } = props || { html: "", className: "" }

  if (!html) {
    return null
  }

  return (
    <Box
      sx={{
        "*": {
          fontFamily: `'SVN-Poppins', 'Poppins', sans-serif !important;`
        },
        overflow: "hidden",
        "& p": {
          margin: 0,
          padding: 0
        },
        "& img": {
          width: "100% !important",
          height: "auto !important"
        }
      }}
    >
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: formatHtmlBreak(html) }}
      />
    </Box>
  )
}

export default DangerouslySetInnerHTML
