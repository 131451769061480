"use client"

import { useState, useEffect } from "react"
import { Controller } from "react-hook-form"
import PhoneInput from "react-phone-input-2"
import { makeStyles } from "tss-react/mui"
import classNames from "classnames"
import { styleCommon, FIELD_HEIGHT } from "./styleCommon"
import "react-phone-input-2/lib/style.css"

const useStyles = makeStyles()({
  root: {
    display: "flex",
    flexDirection: "column",
    "& .react-tel-input": {
      border: "1.5px solid transparent",
      borderRadius: "16px",
      height: FIELD_HEIGHT
    },
    "& .react-tel-input .form-control": {
      height: "100%",
      width: "100%",
      paddingLeft: 0,
      zIndex: 1111,
      border: "none",
      padding: "0px 16px !important",
      borderRadius: "16px",
      backgroundColor: "white",
      fontSize: 16,
      color: "#000",
      "&:active": {
        border: "1.5px solid #C2C7D0"
      },
      "&:focus": {
        border: "1.5px solid #C2C7D0"
      }
    },
    "& .react-tel-input .flag-dropdown": {
      display: "none"
    }
  },
  ...styleCommon
})

const FormPhoneNumber = props => {
  const { classes } = useStyles()
  const {
    required,
    name,
    label,
    placeholder,
    errors,
    control,
    setValue,
    getValues,
    className = "field",
    ...rest
  } = props
  const defaultValue = (getValues && getValues(name)) || ""
  const [stateValue, setStateValue] = useState("")

  useEffect(() => {
    if (defaultValue) {
      setStateValue(defaultValue)
    }
  }, [defaultValue])

  const handleChangeRegion = value => {
    setValue && setValue("region", value)
  }

  const msgError = JSON.stringify(errors) !== "{}" && errors?.[name]?.message

  return (
    <div className={classes.root}>
      {label && (
        <label
          className={classNames(classes.label, {
            [classes.errorLabel]: Boolean(msgError)
          })}
        >
          {label}
          {required && <span className="required">*</span>}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState
        }) => {
          return (
            <PhoneInput
              required={required}
              className={classNames(classes.controller, {
                [classes.errorField]: Boolean(msgError)
              })}
              country="vn"
              onlyCountries={["vn"]}
              name={name}
              type="tel"
              placeholder={placeholder || label}
              error={Boolean(msgError)}
              value={stateValue}
              onChange={(value, data, e, formattedValue) => {
                onChange(formattedValue)
                handleChangeRegion(data.countryCode)
              }}
              onBlur={onBlur}
              // {...rest}
            />
          )
        }}
      />
      {msgError && <span className={classes.errorText}>{msgError}</span>}
    </div>
  )
}

export default FormPhoneNumber
