"use client"

import { Icons } from "@src/components"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import classNames from "classnames"
import { useState } from "react"
import { Controller } from "react-hook-form"
import { styleCommon, FIELD_HEIGHT } from "./styleCommon"
import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& .MuiInputBase-root": {
      padding: 0,
      "& input": {
        height: "auto",
        lineHeight: "28px",
        "&::placeholder": {
          minHeight: "24px",
        }
      }
    },
    "& .MuiInputBase-input": {
      height: "100%",
      width: "100%",
      padding: "auto 16px !important",
      marginBottom: 0
    },
    "& .MuiInputBase-adornedStart": {
      paddingLeft: "8px"
    },
    "& .MuiInputLabel-root": {
      transform: "none",
      position: "unset",
      fontSize: 12,
      lineHeight: "16px",
      color: "#000000",
      fontWeight: "400",
      marginBottom: 4,
      display: "inline-block"
    },
    "& .MuiFormLabel-root.Mui-error": {
      color: "#f44336"
    },
    "& .MuiInputBase-root > .MuiIcon-root": {
      cursor: "pointer",
      fontSize: 15
    },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: " #e7e8e9",
      color: "#878c93"
    },
    "& .MuiInputBase-root textarea": {
      minheight: FIELD_HEIGHT,
      padding: "12px !important"
    },
    "& .Mui-focused": {
      boxShadow: "unset"
    }
  },
  resize: {
    "& .MuiInputBase-root textarea": {
      resize: "auto"
    }
  },
  ...(styleCommon || {})
}))

const FormTextField = props => {
  const { classes } = useStyles()

  const {
    required,
    hiddenRequired,
    name,
    formatMoney,
    noSpace,
    isPercent,
    onlyNumber,
    showLabel = true,
    label,
    placeholder,
    type = "text",
    subLabel,
    haseye = false,
    hiddenField,
    errors,
    control,
    end_adornment,
    start_adornment,
    className = "field",
    inputProps,
    maxRows,
    resize,
    maxLength,
    ...rest
  } = props

  const [fieldType, setFieldType] = useState(type || "text")

  const msgError = errors?.[name]?.message

  const onTogglePassword = () => {
    setFieldType(fieldType === "text" ? "password" : "text")
  }

  return (
    <Box
      className={classNames(classes.root, className)}
      sx={{
        ...(hiddenField && {
          height: 0,
          overflow: "hidden",
          zIndex: -1
        })
      }}
    >
      {subLabel ? (
        <Stack width="100%" justifyContent="space-between">
          {showLabel && (
            <label
              className={classNames(classes.label, {
                [classes.errorLabel]: Boolean(msgError)
              })}
            >
              {label}
              {!hiddenRequired && required && (
                <span className="required">*</span>
              )}
            </label>
          )}
          {subLabel}
        </Stack>
      ) : (
        showLabel && (
          <label
            className={classNames(classes.label, {
              [classes.errorLabel]: Boolean(msgError)
            })}
          >
            {label}
            {!hiddenRequired && required && <span className="required">*</span>}
          </label>
        )
      )}
      <Controller
        name={name}
        control={control}
        render={({
          field: { onChange, onBlur,value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState
        }) => (
          <TextField
            required={required}
            className={classNames(classes.controller, {
              [classes.errorField]: Boolean(msgError),
              [classes.resize]: !!resize
            })}
            name={name}
            value={value} // select value to show
            onBlur={onBlur} // notify when input is touched
            onChange={(e, data) => {
              if (formatMoney) {
                // last character of value === .
                let newValue = e.target.value
                if (newValue[newValue.length - 1] !== ".") {
                  newValue = newValue.replace(/,/g, "")
                  // number after dot
                  const numberAfterDot = newValue.split(".")[1]
                  // number before dot
                  const numberBeforeDot = newValue.split(".")[0]
                  e.target.value =
                    numberBeforeDot.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                      (numberAfterDot ? `.${numberAfterDot}` : "") || ""
                }
              }
              // OTP
              if (maxLength && maxLength === 1) {
                if (e.target.value.length > 1) {
                  e.target.value = e.target.value[1]
                }
              }

              onChange(e)
            }} // send value to hook form
            onKeyPress={event => {
              if (onlyNumber) {
                const eventKey =
                  event.key === "."
                    ? ".0"
                    : event.key === "-"
                      ? "-0"
                      : event.key
                const newValue = (event.target.value + eventKey).replace(
                  /,/g,
                  ""
                )
                if (!/^-?[0-9]+(?:\.[0-9]+)?$/.test(newValue)) {
                  event.preventDefault()
                }
                if (isPercent) {
                  if (Number(newValue) > 100 || Number(newValue) < 0) {
                    event.preventDefault()
                  }
                }
              }

              if (noSpace && event.key === " ") {
                event.preventDefault()
              }
            }}
            type={fieldType}
            variant="outlined"
            size="small"
            maxRows={maxRows || 5}
            placeholder={placeholder || label}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              notched: false,
              startAdornment: start_adornment,
              endAdornment:
                type === "password" && haseye ? (
                  fieldType === "password" ? (
                    <Icons
                      sx={{
                        backgroundColor: "transparent"
                      }}
                      type="button"
                      name="eye-slash"
                      onClick={onTogglePassword}
                    />
                  ) : (
                    <Icons
                      sx={{
                        backgroundColor: "transparent"
                      }}
                      type="button"
                      name="eye"
                      onClick={onTogglePassword}
                    />
                  )
                ) : (
                  end_adornment
                ),
              ...inputProps,
            }}
            fullWidth
            error={Boolean(msgError)}
            {...rest}
          />
        )}
      />
      {msgError && <span className={classes.errorText}>{msgError}</span>}
    </Box>
  )
}
export default FormTextField
