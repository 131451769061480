"use client"
import useMutation from "@src/hooks/use-mutation"
import { Toastify, Icons } from ".."
import { useRouter } from "next/navigation"
import { useCurrentUser } from "@src/hooks/use-current-user"

const LikeIcon = ({ blogId, slug }) => {
  const { mutation } = useMutation()
  const router = useRouter()
  const { update, user } = useCurrentUser()
  const isLiked = user?.blog_ids?.includes(blogId)
  const { blog_ids = [] } = user || {}

  const toggleLike = (status: boolean) => async () => {
    if (slug) {
      if (status) {
        await mutation({
          url: `/auth/wishlist/blog/${slug}/addToWishlist`,
          method: "POST",
          onSuccess: res => {
            if (res) {
              Toastify.success(
                res?.message || "Đã thêm vào danh sách yêu thích"
              )
              update({
                ...user,
                blog_ids: [...blog_ids, blogId],
                type: "LOCAL_UPDATE"
              })
              router.refresh()
            }
          }
        })
      } else {
        await mutation({
          url: `/auth/wishlist/blog/${slug}/removeFromWishlist`,
          method: "DELETE",
          onSuccess: res => {
            if (res) {
              Toastify.success(
                res?.message || "Đã xóa khỏi danh sách yêu thích"
              )
              update({
                ...user,
                blog_ids: blog_ids.filter(
                  item => item !== blogId
                ),
                type: "LOCAL_UPDATE"
              })
              router.refresh()
            }
          }
        })
      }
    }
  }

  return (
    <Icons
      name="heart"
      type="button"
      sx={{
        padding: "5px !important",
        "&:hover": {
          borderRadius: "50%"
        },
        ...(isLiked && {
          "& svg ": {
            fill: "#FC5E5E",
            color: "#FC5E5E"
          }
        })
      }}
      onClick={toggleLike(!isLiked)}
    />
  )
}

export default LikeIcon
