import { useTheme } from "@mui/material/styles"

const Logo = () => {
  const theme = useTheme()

  return (
    <svg
      width="296"
      height="48"
      viewBox="0 0 296 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_261_11345)">
        <path
          d="M24.3064 10.2788L24.4922 47.9488L14.0776 48L13.8918 10.33L-0.149173 10.401L-0.191406 1.48382L38.3699 1.29053L38.415 10.2077L24.3092 10.2788H24.3064Z"
          fill="black"
        />
        <path
          d="M78.3334 29.3099L57.4366 29.4123L57.5267 47.7811L47.1121 47.8351L46.8812 1.24786L57.2986 1.19385L57.3944 20.6286L78.2883 20.5235L78.3334 29.3099Z"
          fill="black"
        />
        <path
          d="M220.314 46.9653L220.086 0.378071L230.501 0.326904L230.729 46.9141L220.314 46.9653Z"
          fill="black"
        />
        <path
          d="M97.3071 38.8639L97.1635 9.71314L121.884 9.59091L121.842 0.872681L86.7067 1.04608L86.9347 47.6333L122.796 47.4542L122.754 38.736L97.3071 38.8639Z"
          fill="black"
        />
        <path
          d="M122.662 20.3102L113.959 20.3531L114.002 29.1395L122.705 29.0966L122.662 20.3102Z"
          fill="black"
        />
        <path
          d="M163.384 21.7373C163.539 20.4553 163.852 19.2415 164.325 18.096C165.04 16.362 166.023 14.8497 167.27 13.5563C168.517 12.263 169.995 11.2453 171.704 10.5062C173.413 9.76432 175.257 9.3891 177.237 9.38057C179.391 9.3692 181.533 9.90361 183.673 10.9781C185.81 12.0555 187.674 13.5194 189.265 15.3756L195.293 7.89106C194.144 6.70001 192.863 5.6312 191.452 4.68177C190.042 3.73519 188.544 2.93358 186.959 2.27409C185.374 1.61746 183.743 1.11432 182.074 0.767521C180.401 0.420725 178.732 0.253012 177.062 0.26154C173.546 0.278595 170.297 0.895438 167.315 2.10638C164.33 3.32017 161.749 4.98593 159.561 7.10366C157.373 9.22139 155.661 11.7143 154.423 14.5825C153.451 16.8282 152.874 19.233 152.674 21.7885L163.387 21.7345L163.384 21.7373Z"
          fill="black"
        />
        <path
          d="M189.349 32.5449C187.685 34.2391 185.804 35.5808 183.698 36.5671C181.595 37.5535 179.486 38.0538 177.377 38.0623C175.401 38.0709 173.551 37.7156 171.834 36.9907C170.116 36.2687 168.63 35.2652 167.368 33.9861C166.11 32.7069 165.113 31.1918 164.381 29.4436C164.083 28.733 163.849 27.9996 163.669 27.2406L152.821 27.2946C153.108 29.418 153.654 31.4391 154.479 33.3522C155.723 36.2516 157.427 38.7616 159.592 40.8794C161.754 42.9999 164.325 44.66 167.295 45.8653C170.268 47.0705 173.467 47.6646 176.896 47.6476C180.28 47.6305 183.617 46.8602 186.905 45.3337C190.194 43.8101 193.063 41.7748 195.509 39.2335L189.346 32.542L189.349 32.5449Z"
          fill="black"
        />
        <path
          d="M285.144 0.0511667L285.277 27.1382C285.294 30.5095 284.373 33.156 282.515 35.0719C281.205 36.4221 279.575 37.292 277.638 37.6984L277.683 47.0278C279.837 46.8289 281.842 46.3939 283.686 45.7146C286.251 44.7708 288.422 43.4291 290.193 41.6894C291.964 39.9498 293.326 37.8463 294.281 35.3789C295.235 32.9115 295.705 30.1457 295.691 27.0871L295.559 0L285.144 0.0511667Z"
          fill="black"
        />
        <path
          d="M272.19 37.6729C270.34 37.2636 268.747 36.4278 267.421 35.1487C265.455 33.2527 264.464 30.6176 264.447 27.2434L264.315 0.156372L253.901 0.207539L254.033 27.2946C254.047 30.3561 254.557 33.1162 255.556 35.5722C256.558 38.0282 257.972 40.1175 259.805 41.8401C261.638 43.5627 263.839 44.8817 266.415 45.7999C268.201 46.4366 270.143 46.8431 272.232 47.0307L272.187 37.6701L272.19 37.6729Z"
          fill="black"
        />
        <path
          d="M212.236 38.1971L203.533 38.24L203.576 47.0263L212.278 46.9834L212.236 38.1971Z"
          fill="black"
        />
        <path
          d="M246.733 38.0269L238.031 38.0698L238.073 46.8562L246.776 46.8132L246.733 38.0269Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_261_11345">
          <rect width="295.68" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Logo
