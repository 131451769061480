"use client"

import { Controller } from "react-hook-form"
import classNames from "classnames"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import { makeStyles } from "tss-react/mui"
import clsx from "clsx"

const useStyles = makeStyles()({
  root: {
    padding: "8px",
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  icon: {
    border: "1.5px solid #434343",
    padding: 0,
    borderRadius: 6,
    width: 18,
    height: 18,
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5"
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)"
    }
  },
  checkedIcon: {
    border: "none",
    backgroundColor: "#1866E1",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23FFF'/%3E%3C/svg%3E\")",
      content: "''",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    },
    "input:hover ~ &": {
      backgroundColor: "#1866E1"
    }
  }
})

const FormCheckBox = props => {
  const { classes } = useStyles()
  const { name, label, control, className = "field", ...rest } = props

  return (
    <FormControlLabel
      sx={{
        "& label": {
          margin: "0px !important"
        },
        "& .MuiFormControlLabel-label": {
          paddingTop: "4px"
        }
      }}
      control={
        <Controller
          {...rest}
          control={control}
          name={name}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState
          }) => (
            <Checkbox
              className={clsx(classes.root, className)}
              disableRipple
              checked={!!value}
              color="default"
              checkedIcon={
                <span
                  className={classNames(classes.icon, classes.checkedIcon)}
                />
              }
              icon={<span className={classes.icon} />}
              onChange={e => onChange(e.target.checked)}
            />
          )}
        />
      }
      label={label}
    />
  )
}
export default FormCheckBox
