import { InventoryAttributeSelector, ProductAttribute } from "../types/common"
import InventoryModel from "./inventory"
export default class ProductModel {
  id: number
  name: string
  lazy_image: string
  flash_sales: any[]
  promotion_price: number | null
  sale_price: number
  liked: boolean
  discount_percent: number
  is_new: boolean
  slug: string
  description: string
  model: string
  material: string
  style: string
  type: string
  is_reorder: boolean
  condition: string
  sku: string | null
  short_description: string
  additional_information: string
  shipping_and_return: string
  code: string
  images: string[]
  inventories: InventoryModel[]
  size_image: string
  attributes: ProductAttribute[]
  out_of_stock: boolean
  meta: string
  is_best_seller: boolean
  image: string
  original_price: number

  get hasPromotion() {
    return this.promotion_price > 0
  }

  getInventoriesByFirstAttribute(value: string): InventoryModel[] {
    return this.inventories.filter(inventory => {
      return inventory.attributes[0].value === value
    })
  }

  getInventoriesByAttributes(values: string[]): InventoryModel[] {
    return this.inventories.filter(inventory => {
      const attributes = inventory.attributes?.filter(attribute =>
        values.includes(attribute.value)
      )
      return attributes.length === values.length
    })
  }

  get firstAttributeOptions(): InventoryAttributeSelector {
    return {
      name: this.attributes[0].name,
      id: this.attributes[0].id,
      options: this.attributes.map(attribute => {
        return {
          label: attribute.value,
          value: attribute.value,
          image: attribute.image,
          small_image: attribute.small_image
        }
      }),
      children: this.attributes[0].children
    }
  }

  get secondAttributeOptions(): InventoryAttributeSelector {
    if (!this.firstAttributeOptions?.children?.length) return null
    const attribute = this.firstAttributeOptions.children[0]
    return {
      name: attribute.name,
      id: attribute.id,
      options: this.firstAttributeOptions.children.map(child => {
        return {
          label: child.value,
          value: child.value,
          image: this.inventories.find(
            inventory =>
              inventory.attributes.filter(a =>
                [child.value, attribute.value].includes(a.value)
              ).length == 2
          )?.image
        }
      }),
      children: []
    }
  }
  static fromJson(json) {
    if (json?.inventories) {
      const product = new ProductModel()
      for (const key in json) {
        if (json.hasOwnProperty(key)) {
          product[key] = json[key]
        }
      }
      product.inventories = product?.inventories.map(inventory =>
        InventoryModel.fromJson(inventory)
      )
      return product
    }
  }
}
