/* eslint-disable import/no-anonymous-default-export */
"use client"

import React from "react"
import { Icons } from "@src/components"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import toast from "react-hot-toast"

const Success = ({ label }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        maxWidth: 320,
        color: "#000",
        fontWeight: 700
      }}
    >
      <Typography
        sx={{
          color: "#000",
          fontWeight: 500,
          fontSize: "13px"
        }}
        variant="body"
      >
        {label}
      </Typography>
    </Box>
  )
}

export default {
  success: label =>
    toast.success(<Success label={label} />, {
      closeButton: (
        <Box
          sx={{
            width: "16px",
            height: "16px",
            "& img": {
              width: "100%",
              height: "100%"
            }
          }}
        >
          <Icons name="close" />
        </Box>
      )
    }),
  error: label =>
    toast.error(label, {
      closeButton: (
        <Box
          sx={{
            width: "16px",
            height: "16px",
            "& img": {
              width: "100%",
              height: "100%"
            }
          }}
        >
          <Icons name="close-white" />
        </Box>
      )
    }),
  info: label =>
    toast.info(label, {
      closeButton: (
        <Box
          sx={{
            width: "16px",
            height: "16px",
            "& img": {
              width: "100%",
              height: "100%"
            }
          }}
        >
          <Icons name="close-white" />
        </Box>
      )
    })
}
