"use client"

import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableContainer from "@mui/material/TableContainer"
import { useEffect, useState } from "react"
import EnhancedTableBody from "./EnhancedTableBody"
import EnhancedTableHead from "./EnhancedTableHead"
import Pagination from "@src/components/Pagination"
import useResponsive from "@src/hooks/use-responsive"

export default function EnhancedTable(props) {
  const {
    hiddenPagination,
    showCheckbox,
    headCells = [],
    rows = [],
    loading,
    page,
    totalPages,
    onRowClick,
    onChangePage,
    columnSortDefault,
    sortType,
    selected = [],
    setSelected,
    isSelectAll,
    setIsSelectAll,
    isExceptIds,
    setIsExceptIds
  } = props

  const { isMobile } = useResponsive()

  const [order, setOrder] = useState(sortType || "asc")
  const [orderBy, setOrderBy] = useState(columnSortDefault || null)

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (!isCheckAll()) {
      setIsSelectAll(true)
      setIsExceptIds(false)
      setSelected([])
      return
    }

    if (isSelectAll && !isExceptIds) {
      setIsSelectAll(false)
      setSelected([])
      setIsExceptIds(false)
      return
    }
    if (isSelectAll && isExceptIds) {
      setIsSelectAll(false)
      setIsExceptIds(false)
      setSelected([])
      return
    }
    if (selected.length === 0) {
      setIsSelectAll(true)
    }
  }

  const handleClick = (_, id) => {
    if (showCheckbox) {
      const selectedIndex = selected.indexOf(id)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      }
      setSelected(newSelected)

      if (newSelected.length === 0) {
        setIsExceptIds(false)
      } else {
        setIsExceptIds(true)
      }
    }
    onRowClick?.(id)
  }

  const isCheckAll = () => {
    if (isSelectAll && selected.length === 0 && !isExceptIds) {
      return true
    }
    if (isSelectAll && isExceptIds && selected.length !== 0) {
      return false
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer
          sx={{
            borderRadius: "16px"
          }}
        >
          <Table aria-labelledby="tableTitle">
            <EnhancedTableHead
              isMobile={isMobile}
              showCheckbox={showCheckbox}
              headCells={headCells}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              isCheckAll={isCheckAll}
            />
            <EnhancedTableBody
              loading={loading}
              showCheckbox={showCheckbox}
              headCells={headCells}
              rows={rows}
              page={page}
              order={order}
              orderBy={orderBy}
              rowsPerPage={20}
              selected={selected}
              onClick={handleClick}
              isSelectAll={isSelectAll}
              isExceptIds={isExceptIds}
            />
          </Table>
        </TableContainer>
      </Paper>
      {!hiddenPagination && (
        <Pagination page={page} total={totalPages} onChange={onChangePage} />
      )}
    </Box>
  )
}
