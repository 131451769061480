import React from 'react'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import { width } from '@mui/system'

const ProductSkeleton = ({ loading  }) => {

  if (!loading) {
    return null
  }

  return (
    <Stack
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: "#fff",
        zIndex: 999,
        height: "100%",
        width: "100%",
      }}
      gap={{
        xs: "16px",
        md: "32px"
      }}
      flexDirection={{
        xs: "column",
        md: "row"
      }}
    >
      <Box
        width={{
          xs: "100%",
          md: "55%"
        }}
        maxWidth={{
          xs: "100%",
          md: "55%"
        }}
        height="100%"
        pb="16px"
      >
        <Skeleton
          sx={{
            borderRadius: "8px",
            width: "100%",
            height: "100%",
          }}
          variant="rectangular"
          animation="wave"
        />
      </Box>
      <Box
        width={{
          xs: "100%",
          md: "45%"
        }}
        maxWidth={{
          xs: "100%",
          md: "45%"
        }}
        display={{
          xs: "none",
          md: "block"
        }}
      >
        <Skeleton animation="wave" sx={{
          height: "60px",
          width: {
            xs: "100%",
            md: "calc(100% - 16px)"
          },
          transform: "none",
          borderRadius: "4px"
        }} />
        <Skeleton animation="wave" sx={{
          marginTop: "16px",
          height: "160px",
          width: {
            xs: "100%",
            md: "calc(100% - 16px)"
          },
          transform: "none",
          borderRadius: "4px"
        }} />
        <Skeleton animation="wave" sx={{
          marginTop: "16px",
          height: "160px",
          width: {
            xs: "100%",
            md: "calc(100% - 16px)"
          },
          transform: "none",
          borderRadius: "4px"
        }} />
      </Box>
    </Stack>
  )
}

export default ProductSkeleton