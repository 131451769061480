"use client"
import { memo } from "react"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import { Icons } from "@src/components"
import { styled } from "@mui/system"

const MuiSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-icon": {
    height: "100%",
    position: "absolute",
    top: 0,
    bottom: 0
  }
}))

const MuiMenuItem = styled(MenuItem)(({ theme }) => ({
  "& em": {
    fontStyle: "normal"
  }
}))

const renderOptions = data => {
  if (data?.length > 0)
    return data.map((item, idx) => (
      <MuiMenuItem key={idx} value={item.value}>
        {item.label}
      </MuiMenuItem>
    ))

  return (
    <MuiMenuItem value="">
      <em>None</em>
    </MuiMenuItem>
  )
}

const SelectField = props => {
  const {
    options = [],
    value,
    onChange,
    inputProps = { "aria-label": "Without label" },
    ...rest
  } = props
  return (
    <MuiSelect
      value={value || options[0]?.value}
      onChange={onChange}
      displayEmpty
      inputProps={inputProps}
      IconComponent={props => (
        <Icons {...props} name="arrow-down" type="button" />
      )}
      sx={{
        width: "fit-content"
      }}
      {...rest}
    >
      {renderOptions(options)}
    </MuiSelect>
  )
}

export default memo(SelectField)
