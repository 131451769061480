import Box from "@mui/material/Box"
import { minWidth } from "@mui/system"
import { Modal, NextImage } from "@src/components"

export default function SizeModal(props) {
  const { imgUrl, open, onClose } = props

  return (
    <Modal
      title="Hướng dẫn chọn size"
      maxWidth="lg"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          margin: "0px 16px",
          overflow: "auto",
          width: "fit-content",
          minWidth: {
            xs: "calc(100% - 32px)",
            md: "auto",
          }
        }
      }}
    >
      <NextImage
        alt="product size"
        src={imgUrl}
        sx={{
          width: "100%",
          height: "auto",
          "& img": {
            position: "relative !important",
            maxHeight: "80vh",
          },
        }}
      />
    </Modal>
  )
}
