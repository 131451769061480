"use client"

import React from "react"
import Link from "next/link"
import { useRouter, useParams, usePathname } from "next/navigation"
import Stack from "@mui/material/Stack"
type Props = {
  [x: string]: any;
  checkActive?: boolean;
  sx?: any;
  children: any;
  skipLocaleHandling?: boolean;
  colorHover?: string;
  className?: string;
}
const NextLink = ({
  checkActive,
  sx,
  children,
  skipLocaleHandling,
  colorHover,
  className,
  onClick,
  style,
  passHref,
  target = "_self",
  ...rest
}: Props) => {
  const router = useRouter()
  const params = useParams()
  const pathname = usePathname()

  const locale = rest.locale || params.locale || ""

  let href = rest.href || ""
  if (href && href.indexOf("http") === 0) skipLocaleHandling = true
  if (locale && !skipLocaleHandling) {
    href = href ? `/${locale}${href}` : pathname.replace("[locale]", locale)
  }

  return (
    <Stack
      sx={{
        "& a": {
          color: "#000",
          fontSize: 14,
          textDecoration: "none",
          "&:hover": {
            color: colorHover || "#3f80e6"
          }
        },
        ...sx
      }}
    >
      <Link style={style} className={className} href={href} onClick={onClick} passHref={passHref} target={target}>
        {children}
      </Link>
    </Stack>
  )
}

export default NextLink
