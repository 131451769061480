import Box from "@mui/material/Box"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import Checkbox from "@mui/material/Checkbox"
import { visuallyHidden } from "@mui/utils"
import Icons from "@src/components/Icons"
import { MuiTableCell } from "../styles"
import { styled } from "@mui/system"

const MuiTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  fontSize: 14,
  color: "#575F6D",
  fontWeight: 400,
  textTransform: "capitalize"
}))

const IconSortCustom = props => {
  return (
    <Box {...props}>
      <Icons name="sort" />
    </Box>
  )
}

function EnhancedTableHead(props) {
  const {
    showCheckbox,
    headCells,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    isCheckAll
  } = props
  const createSortHandler = property => event => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow
        sx={{
          backgroundColor: "#434343",
          "& th": {
            color: "white",
            fontWeight: 700,
            fontSize: 14
          }
        }}
      >
        {showCheckbox && (
          <MuiTableCell padding="checkbox">
            <Checkbox
              color="primary"
              // indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={isCheckAll()}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts"
              }}
            />
          </MuiTableCell>
        )}
        {headCells.map(
          ({ id, label, width = "auto", align, isSort, ...rest }) => (
            <MuiTableCell
              key={id}
              align={align || "left"}
              sortDirection={orderBy === id ? order : false}
              width={width}
              {...rest}
            >
              {isSort ? (
                <MuiTableSortLabel
                  active={orderBy === id}
                  direction={orderBy === id ? order : "asc"}
                  onClick={createSortHandler(id)}
                  IconComponent={IconSortCustom}
                >
                  {label}
                  {orderBy === id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </MuiTableSortLabel>
              ) : (
                label
              )}
            </MuiTableCell>
          )
        )}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
