"use client"

import { useState, useMemo } from "react"
import Popover from "@mui/material/Popover"
import SingleDatePicker from "../../DatePicker/SingleDatePicker"
import dayjs from "dayjs"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Icons from "@src/components/Icons"
import Typography from "@mui/material/Typography"

import { useStyles } from "./styles"

export default function InputDatePicker(props) {
  const {
    hasWeek = true,
    formatDate = "DD/MM/YYYY",
    content,
    selectedDate = new Date(),
    onChange,
    onClickPrev,
    onClickNext,
    sxWrapper,
    ...rest
  } = props
  const { classes } = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const title = useMemo(
    () => dayjs(selectedDate).format("DD/MM/YYYY"),
    [selectedDate]
  )

  const handleOpenDatePicker = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseDatePicker = () => {
    setAnchorEl(null)
  }
  const open = useMemo(() => Boolean(anchorEl), [anchorEl])

  return (
    <Box className={classes.root} sx={sxWrapper}>
      <Box className={classes.inputRoot}>
        <Button
          disableRipple
          variant="contained"
          color="primary"
          className="date"
          startIcon={<Icons name="calendar" />}
          onClick={handleOpenDatePicker}
        >
          <Typography component="span" paddingTop="4px" fontSize="12px">
            {content || title}
          </Typography>
        </Button>
      </Box>
      <Popover
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseDatePicker}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <SingleDatePicker
          {...rest}
          show={open}
          hasWeek={hasWeek}
          value={selectedDate}
          container={anchorEl}
          currentMonth={selectedDate}
          onClose={handleCloseDatePicker}
          onChange={onChange}
        />
      </Popover>
    </Box>
  )
}
