"use client"
import { useState } from "react"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import ContainerLoading from "@src/components/ContainerLoading"
import { useResponsive } from "@src/hooks"
import ProductModel from "@src/models/product"
import FavoriteIcon from "./favorite-Icon"
import ImageCarouselDesktop from "./image-carousel-desktop"
import ImageCarouselMobile from "./image-carousel-mobile"
import InfoAndInventory from "./info-and-inventory"
import SizeModal from "./size-modal"
import BadgeProductType from "./badge-type"
import ProductSkeleton from "./product-skeleton"

type Props = {
  isModal?: boolean
  detail: ProductModel
  fetchingId: boolean
}

const ProductDetail = (props: Props) => {
  const { isModal, detail, fetchingId } = props
  const { isMobile, isDesktop } = useResponsive()
  const [selectedImage, setSelectedImage] = useState<string>("")
  const [openSizeModal, setOpenSizeModal] = useState(false)

  const handleShowSizeModal = (e) => {
    e.preventDefault()
    setOpenSizeModal(true)
  }

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        minHeight: {
          xs: "500px",
          lg: "660px"
        },
        minWidth: {
          xs: "auto",
          lg: "960px"
        }
      }}
    >
      <ProductSkeleton loading={fetchingId} />
      <Box position="relative">
        <Stack
          mt={2}
          position="relative"
          flexDirection={{
            xs: "column",
            md: "row"
          }}
          gap={{
            xs: "16px",
            md: "32px"
          }}
        >
          <Box sx={{
            position: "absolute",
            top: {
              xs: 16,
              md: 4
            },
            left: {
              xs: 8,
              md: 4
            },
            zIndex: 99
          }}
          >
            <BadgeProductType
              detail={detail}
            />
          </Box>
          {isMobile && (
            <Box width="100%" height="auto">
              <ImageCarouselMobile
                isLoading={fetchingId}
                isModal={isModal}
                name={detail?.name}
                detail={detail}
                selectedImage={selectedImage}
              />
              {!isModal && (
                <Stack
                  display={{
                    xs: "flex",
                    md: "none"
                  }}
                  alignItems="center"
                  mt={1}
                  mb={-1}
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      paddingRight: "12px"
                    }}
                  >
                    <FavoriteIcon productId={detail?.id} slug={detail?.slug} />
                  </Box>
                  <Typography variant="body1">
                    Thêm vào danh sách yêu thích
                  </Typography>
                </Stack>
              )}
            </Box>

          )}
          {isDesktop && (
            <Box
              width="55%"
              maxWidth="55%"
            >
              <ImageCarouselDesktop
                isModal={isModal}
                detail={detail}
                selectedImage={selectedImage}
              />
            </Box>
          )}
          <Box
            width={{
              xs: "100%",
              md: "45%"
            }}
            maxWidth={{
              xs: "100%",
              md: "45%"
            }}
          >
            <InfoAndInventory
              isMobile={isMobile}
              isModal={isModal}
              detail={detail}
              onSelectedImage={setSelectedImage}
              onOpenSizeModal={handleShowSizeModal}
            />
          </Box>

        </Stack>

        <Stack
          display={{
            xs: "none",
            md: "flex"
          }}
          alignItems="center"
          mt={1.5}
        >
          <Box
            sx={{
              cursor: "pointer",
              backgroundColor: "white",
              padding: "4px",
              marginRight: "12px",
              borderRadius: "50%"
            }}
          >
            <FavoriteIcon productId={detail?.id} slug={detail?.slug} />
          </Box>
          <Typography variant="body1">
            Thêm vào danh sách yêu thích
          </Typography>
        </Stack>
      </Box>
      <SizeModal
        imgUrl={detail?.size_image || ""}
        open={openSizeModal}
        onClose={() => setOpenSizeModal(false)}
      />
    </Box>

  )
}

export default ProductDetail
