"use client"
import { useState, useEffect, useMemo } from "react"
import ProductModel from "@src/models/product"
import { InventoryAttributeSelector } from "@src/types/common"
import InventoryModel from "@src/models/inventory"
import useMutation from "@src/hooks/use-mutation"
import { Toastify } from "@src/components"

const useInventoryProduct = (
  product: ProductModel,
  updateApiAfterChange = true
) => {
  const [productData, setProductData] = useState<ProductModel>()
  const [firstAttributeOptions, setFirstAttributeOptions] =
    useState<InventoryAttributeSelector>(productData?.firstAttributeOptions)
  const [secondAttributeOptions, setSecondAttributeOptions] =
    useState<InventoryAttributeSelector>(productData?.secondAttributeOptions)
  const [count, setCount] = useState<number>(0)
  const [currentInventory, setCurrentInventory] = useState<InventoryModel>()

  useEffect(() => {
    if (product) {
      setProductData(ProductModel.fromJson(product))
    }
  }, [product])


  const handleUpdateInventory = async (inventories: InventoryModel[]) => {
    const inventory = inventories?.length ? inventories[0] : null
    setCurrentInventory(inventory)
    setCount(inventory?.stock_quantity > 0 ? 1 : 0)
  }

  useEffect(() => {
    if (productData) {
      setFirstAttributeOptions(productData.firstAttributeOptions)
      setSecondAttributeOptions(productData.secondAttributeOptions)
      handleUpdateInventory(productData.inventories)
    }
  }, [productData])

  const { mutation, loadingWithKey } = useMutation()

  const handleChangeFirstAttribute = async value => {
    const inventories = productData.getInventoriesByFirstAttribute(value)
    const inventory = inventories?.length ? inventories[0] : null
    handleUpdateInventory(inventories)
  }
  const handleChangeSecondAttribute = async value => {
    const inventories = productData?.getInventoriesByAttributes([
      currentInventory.firstAttribute.value,
      value
    ])
    const inventory = inventories?.length ? inventories[0] : null
    handleUpdateInventory(inventories)
  }

  const handleAddToCart = async ({
    showToast = true,
    onSuccess = () => {},
    loadingKey = "addCart"
  }) => {
    if (currentInventory && updateApiAfterChange) {
      await mutation({
        url: "/auth/cart/add",
        method: "POST",
        params: {
          inventory_id: currentInventory.id,
          quantity: 1
        },
        config: {
          deviceAuthenticated: true
        },
        loadingKey,
        onSuccess: res => {
          if (showToast) Toastify.success("Đã thêm vào giỏ hàng!")
          onSuccess?.()
        }
      })
    }
  }

  const handleUpdateQuantity = async (value: number) => {
    setCount(value)
  }

  return {
    onUpdateQuantity: handleUpdateQuantity,
    firstAttributeOptions,
    secondAttributeOptions,
    handleChangeFirstAttribute,
    handleChangeSecondAttribute,
    currentInventory,
    handleAddToCart,
    count,
    loadingWithKey
  }
}

export default useInventoryProduct
