"use client"

import { useState } from "react"
import { makeStyles } from "tss-react/mui"
import { Controller } from "react-hook-form"

import PropTypes from "prop-types"
import classNames from "classnames"
import { DayPicker } from "react-day-picker"

import Button from "@mui/material/Button"
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import Popover from "@mui/material/Popover"
import dayjs from "dayjs"

import { FIELD_HEIGHT } from "./styleCommon"

const useStyles = makeStyles()(theme => ({
  root: {
    boxSizing: "inherit",
    display: "flex",
    flexDirection: "column"
  },
  inputRoot: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "500px",
    height: FIELD_HEIGHT,
    "& button": {
      minHeight: 42,
      boxShadow: "none",
      outline: 0,
      background: "white",
      fontSize: 16,
      fontWeight: 600,
      color: "#383838",
      border: "solid 0.5px #383838",
      borderRadius: 0,
      "&:hover": {
        background: "white",
        boxShadow: "none",
        outline: 0
      },
      "&.prev": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        borderRight: "none"
      },
      "&.next": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        borderLeft: "none"
      },
      "&.date": {
        flex: "1",
        borderLeft: "none",
        borderRight: "none",
        minWidth: 210,
        textTransform: "capitalize"
      }
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "500px"
    }
  },
  popover: {
    marginTop: 16,
    "& .MuiPopover-paper": {
      borderRadius: 10
    }
  },
  datepickerRoot: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid rgba(103, 103, 103, 0.16)",
    background: "white",
    "& .datepicker": {
      "& .DayPicker-Day": {
        padding: "0.5em ",
        border: "20px solid transparent",
        borderRadius: 0,
        [theme.breakpoints.down("md")]: {
          padding: "0"
        }
      }
    },
    "& .group-button": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      borderLeft: "1px solid #dbdbdb",
      padding: theme.spacing(1),
      width: 124,
      "& button": {
        color: "#2c2c2c",
        fontSize: 14,
        lineHeight: "20px",
        textTransform: "initial",
        backgroundColor: "#f5f5f5",
        boxShadow: "none",
        outline: 0,
        "&:active": {
          backgroundColor: "#ca8888"
        }
      },
      [theme.breakpoints.down("md")]: {
        display: "none"
      }
    }
  }
}))

const FormDateTimePicker = ({
  control,
  label,
  name,
  type,
  defaultFormat = "YYYY-MM-DD",
  className = "field",
  onNextClick,
  onPrevClick,
  ...props
}) => {
  const { classes } = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpenDatepicker = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseDatepicker = () => {
    setAnchorEl(null)
  }

  const handleDayChange = (date, onChange) => {
    onChange && onChange(date)
    handleCloseDatepicker()
  }

  const handlePrevChange = (date, onChange) => {
    const yesterday = dayjs(date).add(-1, "days")
    onChange && onChange(yesterday)
    onPrevClick && onPrevClick(yesterday)
  }

  const handleNextChange = (date, onChange) => {
    const tomorrow = dayjs(date).add(1, "days")
    onChange && onChange(tomorrow)
    onPrevClick && onNextClick(tomorrow)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <div className={classNames(classes.root, className)}>
      <Controller
        control={control}
        name={name}
        render={({ onChange, value }) => (
          <div className={classes.root}>
            <div className={classes.inputRoot}>
              <Button
                variant="contained"
                className="prev"
                onClick={_ => handlePrevChange(value, onChange)}
              >
                <NavigateBeforeIcon />
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="date"
                onClick={handleOpenDatepicker}
              >
                {value && dayjs(value).format(defaultFormat)}
              </Button>
              <Button
                variant="contained"
                className="next"
                onClick={_ => handleNextChange(value, onChange)}
              >
                <NavigateNextIcon />
              </Button>
            </div>
            <Popover
              id={id}
              className={classes.popover}
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseDatepicker}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
            >
              <div className={classes.datepickerRoot}>
                <div>
                  <DayPicker
                    className="datepicker"
                    selectedDays={value && dayjs(value).toDate()}
                    onDayClick={date => handleDayChange(date, onChange)}
                  />
                </div>
              </div>
            </Popover>
          </div>
        )}
        {...props}
      />
    </div>
  )
}
FormDateTimePicker.propTypes = {
  type: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  className: PropTypes.object
}
export default FormDateTimePicker
